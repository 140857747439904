import { useBoolean } from "@chakra-ui/hooks"
import Icon from "@chakra-ui/icon"
import { Box, Center, Flex, Grid, GridItem, Heading, Text, VStack } from "@chakra-ui/layout"
import { Spinner } from "@chakra-ui/spinner"
import { chakra, ChakraProps } from "@chakra-ui/system"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { BiCheck, BiChevronDown } from "react-icons/bi"
import Layout from "src/components/Layout"
import OfferTimer from "src/components/OfferTimer"
import Section from "src/components/Section"
import SEO from "src/components/SEO"
import { includedExtras, obligatoryExtras } from "src/domain/boat"
import { dateToLongString, durationInDays } from "src/domain/offer"
import useBoat from "src/lib/hooks/useBoat"
import useOffer from "src/lib/hooks/useOffer"
import { Boat } from "src/lib/types/boat"
import { Offer } from "src/lib/types/offer"
import { DropPoint } from "./Icons"
import { formatPrice } from "./PriceTag"

export enum PaymentType {
  BANK = "BANK",
  CARD = "CARD",
  UNSPECIFIED = "UNSPECIFIED",
}

export interface ReserveFormInputs {
  name: string
  email: string
  phone: string
  message: string
  extras: Array<string>
  paymentType: PaymentType
  tcAccepted: boolean
}
export interface ReserveBoatRequest extends ReserveFormInputs {
  boatName: string
  checkIn: string
  checkOut: string
  base: string
  price: number
  bookingManagerLink: string
}

interface ReserveBoxProps extends ChakraProps {
  title: string
  children: React.ReactNode
  boxDetailsOpen?: boolean
  showTotalPrice?: boolean
  totalPrice?: number
  totalPriceCurrency?: string
  lastLeft?: string
  lastRight?: string
}

interface PriceBoxProps {
  children: React.ReactNode
  offer: Offer
  boat: Boat
}

const PriceBox = ({ children, offer, boat }: PriceBoxProps) => {
  const totalPrice =
    offer.discountedPrice +
    obligatoryExtras(boat.extras)
      .filter((e) => !e.payableInBase)
      .reduce((total, e) => (e.currency === offer.currency ? total + e.price : total), 0)
  return (
    <ReserveBox title="Your price summary" totalPriceCurrency={offer.currency} totalPrice={totalPrice} showTotalPrice>
      {children}
    </ReserveBox>
  )
}

const ReserveBox = ({
  children,
  boxDetailsOpen = true,
  showTotalPrice = false,
  totalPrice,
  totalPriceCurrency,
  lastLeft,
  lastRight,
  title,
  ...rest
}: ReserveBoxProps) => {
  const [open, setOpen] = useBoolean(boxDetailsOpen)
  return (
    <Box {...rest} borderWidth="1px" borderColor="#EEEEEE">
      <Flex
        align="center"
        justifyContent="space-between"
        backgroundColor="#E6EBF030"
        p={[3, 4]}
        onClick={setOpen.toggle}
      >
        <Heading as="h3" variant="h3" size="md">
          {title}
        </Heading>
        <Icon display={["inline-block", "none"]} color="primary" as={BiChevronDown} mr={[3, 4]} />
      </Flex>
      <Box display={open ? "block" : "none"} p={[3, 4]}>
        {children}
      </Box>
      {showTotalPrice && (
        <Flex
          p={[3, 4]}
          fontSize="lg"
          fontWeight="bold"
          backgroundColor="#00B67A0A"
          borderWidth="1px"
          borderColor="#00B67A"
          justifyContent="space-between"
        >
          <Text>Total Price</Text>
          <Text>{formatPrice(totalPrice, totalPriceCurrency)}</Text>
        </Flex>
      )}
    </Box>
  )
}

interface ReserveTemplateProps {
  boatUid: string
  children: React.ReactNode
  step: number
}

const ReserveTemplate = ({ boatUid, children, step }: ReserveTemplateProps) => {
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const boatParams = Object.fromEntries(new URLSearchParams(location.search))
  const offerId = boatParams.offerId

  const { boat, isBoatLoading } = useBoat(boatUid)
  const { offer, isOfferLoading } = useOffer(offerId)

  //   const [search] = useLocalStorageState("searchParams")
  //   useDripTrack("Visit boat reserve", boatDripData(boat, offer, search), { enabled: !!boat && !!offer })
  useEffect(() => {
    if (isBoatLoading || isOfferLoading) return
    if (!boat || !offer) {
      navigate("/")
    }
  })

  const [timesUp, setTimesUp] = useState(false)

  if (!offer) {
    return null
  }

  return (
    <Layout invertedNav noHero>
      {isBoatLoading ? (
        <Center h={400} w="100%">
          <Spinner />
        </Center>
      ) : !boat ? (
        <Center h={400} w="100%">
          <Text>Boat not found</Text>
        </Center>
      ) : (
        <>
          <SEO title={`${boat.modelName} | Reserve your dream boat`} noindex nofollow />
          {/* <Section mt={8}>
            <OfferTimer offerId={offerId} timesUp={timesUp} setTimesUp={setTimesUp} />
          </Section> */}
          {/* Kill progress bar for now
          <Section mt={[12, 16]}>
            <Progress step={step} />
          </Section> */}
          <Section mt={[12, 16]}>
            <Grid
              gridRowStart={[3, 1]}
              gridColumnStart={1}
              gap={8}
              templateColumns={["1fr", "1fr 2fr"]}
              templateRows={"auto 1fr"}
            >
              <GridItem rowSpan={[1, 2]}>
                <VStack align="stretch" spacing={8}>
                  <ReserveBox title="Your booking details">
                    <Grid templateColumns={["50% 50%", "1fr 1fr"]}>
                      <Text>Check-in</Text>
                      <Text>Check-out</Text>
                      <Text fontWeight="bold" borderColor="#A9B2B9" borderRightWidth="1px" mr={4}>
                        {dateToLongString(offer.checkin)}
                      </Text>
                      <Text fontWeight="bold">{dateToLongString(offer.checkout)}</Text>
                      <Text
                        fontSize="sm"
                        fontWeight="bold"
                        borderColor="#A9B2B9"
                        borderRightWidth="1px"
                        color="#A9B2B9"
                        mr={4}
                      >
                        9:00 AM - 10:00 AM
                      </Text>
                      <Text fontSize="sm" fontWeight="bold" color="#A9B2B9">
                        5:00 PM - 6:00 PM
                      </Text>
                    </Grid>
                    <Box mt={3}>
                      <Text>Total length of rental</Text>
                      <Text fontWeight="bold">{durationInDays(offer.checkin, offer.checkout, "night")}</Text>
                    </Box>
                    <Box mt={3}>
                      <Text>Yacht pick up and drop-off</Text>
                      <Text fontWeight="bold">{offer.startBase.name}</Text>
                    </Box>
                  </ReserveBox>
                  {/* WIP
                  <HStack p={3} alignItems="center" borderWidth="1px" borderColor="primary" bg="#66B8FF0A">
                    <BellIcon w={8} h={8} />
                    <Heading as="h3" variant="h3" size="sm">
                      This boat has been booked 7 times this season
                    </Heading>
                  </HStack> */}
                  <PriceBox offer={offer} boat={boat}>
                    <Flex fontWeight="bold" justifyContent="space-between">
                      <Text>Boat Price</Text>
                      <Text>{formatPrice(offer.startPrice, offer.currency)}</Text>
                    </Flex>
                    {obligatoryExtras(boat.extras).length !== 0 &&
                      obligatoryExtras(boat.extras)
                        .filter((e) => e.price > 0)
                        .filter((e) => !e.payableInBase)
                        .map((e) => (
                          <Flex key={`oe-${e.id}`} mt={1} fontWeight="bold" justifyContent="space-between">
                            <Text>{e.name}</Text>
                            <Text>{formatPrice(e.price, e.currency)}</Text>
                          </Flex>
                        ))}
                    {offer.startPrice - offer.discountedPrice > 0 && (
                      <Flex color="#96D9F0" mt={1} fontWeight="bold" justifyContent="space-between">
                        <Text>HighSails Discount</Text>
                        <Text>-{formatPrice(offer.startPrice - offer.discountedPrice, offer.currency)}</Text>
                      </Flex>
                    )}
                  </PriceBox>
                  {includedExtras(boat.extras).length !== 0 && (
                    <ReserveBox title="Your booking includes">
                      <VStack alignItems="flex-start">
                        {includedExtras(boat.extras).map((e) => (
                          <Text key={e.id} fontWeight="bold" color="#00B67A">
                            <Icon as={BiCheck} /> {e.name}
                          </Text>
                        ))}
                      </VStack>
                    </ReserveBox>
                  )}
                  <ReserveBox title="How much will it cost to cancel">
                    <Text mb={2}>
                      <strong>50%</strong> Refund if cancel <strong>prior to</strong> 30 days before embarkation
                    </Text>
                    <Text>
                      <strong>0%</strong> Refund if cancel <strong>after</strong> 30 days before embarkation
                    </Text>
                  </ReserveBox>
                </VStack>
              </GridItem>
              <GridItem gridRowStart={1} gridColumnStart={[1, 2]}>
                <Flex flexDirection={["column", "row"]} borderWidth="1px" borderColor="#EEEEEE" p={[3, 4]}>
                  <chakra.img src={boat.cardImageSrc} alt={boat.modelName} width={["100%", 210]} />
                  <Box ml={3} mt={[3, 0]}>
                    <Text color="#A9B2B9" textTransform="capitalize">
                      {boat.kind?.toLowerCase()}
                    </Text>
                    <Text fontWeight="bold" fontSize="lg">
                      {boat.modelName}
                    </Text>
                    <Flex align="center">
                      <DropPoint />
                      <Text textDecoration="underline">{offer?.startBase.name}</Text>
                    </Flex>
                    <Flex flexDirection="column" mt={3} alignItems="flex-start">
                      {includedExtras(boat.extras).map((e) => (
                        <Text key={e.id} fontWeight="bold" color="#00B67A">
                          <Icon as={BiCheck} /> {e.name}
                        </Text>
                      ))}
                    </Flex>
                  </Box>
                </Flex>
              </GridItem>
              <GridItem gridRowStart={2} gridColumnStart={[1, 2]}>
                {children(timesUp)}
              </GridItem>
            </Grid>
          </Section>
        </>
      )}
    </Layout>
  )
}

export default ReserveTemplate
