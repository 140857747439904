import { navigate, PageProps } from "gatsby"
import { useLocation } from "@reach/router"
import React, { useEffect, useState } from "react"
import ReserveTemplate, { PaymentType, ReserveBoatRequest, ReserveFormInputs } from "src/components/ReserveTemplate"
import { FormErrorMessage, FormLabel, FormControl, Heading, Input, Box, Textarea, Flex, Button } from "@chakra-ui/react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useLocalStorageState } from "src/gatsby/useSessionStorageState"
import { reserveBoat } from "src/client/lambdas"
import useBoat from "src/lib/hooks/useBoat"
import ThankYou from "src/components/ThankYou"
import useOffer from "src/lib/hooks/useOffer"

const ReserveDetails = (props: PageProps<{ boatUid: string }>) => {
  const { boatUid } = props.params

  const defaultValues: ReserveFormInputs = {
    name: "",
    email: "",
    phone: "",
    message: "",
    extras: [],
    paymentType: PaymentType.UNSPECIFIED,
    tcAccepted: false,
  }

  const [reserveFormValues, setReserveFormValues] = useLocalStorageState("reserveFormValues", defaultValues)

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<ReserveFormInputs>({ defaultValues: reserveFormValues })

  const location = useLocation()
  const offerId = new URLSearchParams(location.search).get("offerId")

  const { boat, isBoatLoading } = useBoat(boatUid)
  const { offer, isOfferLoading } = useOffer(offerId)

  useEffect(() => {
    if (isBoatLoading || isOfferLoading) return
    if (!boat || !offer) {
      navigate("/")
    }
  })

  const [formSubmitSuccess, setFormSubmitSucces] = useState(false)

  const onSubmit: SubmitHandler<ReserveFormInputs> = async (values) => {
    setReserveFormValues(values)
    if (!boat || !offer) {
      return
    }
    const reserveBoatRequest: ReserveBoatRequest = {
      ...values,
      boatName: boat.name,
      checkIn: offer.checkin,
      checkOut: offer.checkout,
      base: offer.startBase.name,
      price: offer.discountedPrice,
      bookingManagerLink: `https://portal.booking-manager.com/wbm2/page.html?view=YachtDetails&yachtId=${boat.id}&addMargins=true&setlang=en`,
    }
    const { status } = await reserveBoat(reserveBoatRequest)
    if (status === 200) {
      setFormSubmitSucces(true)
    }
    // navigate(`/boats/${boatUid}/reserve-payment?offerId=${offerId}`)
  }

  // Old Navigation
  // const onSubmit: SubmitHandler<ReserveFormInputs> = (values) => {
  //   setReserveFormValues(values)
  //   navigate(`/boats/${boatUid}/reserve-preferences?offerId=${offerId}`)
  // }

  return (
    <ReserveTemplate boatUid={boatUid} step={1}>
      {(timesUp: boolean) => (
        <>
          {formSubmitSuccess ? (
            <ThankYou type="reserve" />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Heading as="h3" variant="h3" size="lg" mb={4}>
                Enter your details
              </Heading>
              <Box backgroundColor="#EBF2F9" p={4}>
                <FormControl isInvalid={errors.name !== undefined}>
                  <FormLabel htmlFor="name">Name*</FormLabel>
                  <Input
                    id="name"
                    placeholder="Name and Surname"
                    {...register("name", {
                      required: "This is required",
                      minLength: { value: 3, message: "Minimum length should be 3" },
                    })}
                  />
                  <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.email !== undefined} mt={4}>
                  <FormLabel htmlFor="email">E-mail*</FormLabel>
                  <Input
                    id="email"
                    placeholder="your@email.com"
                    {...register("email", {
                      required: "This is required",
                      pattern: { value: /^\S+@\S+$/i, message: "The value should be an email" },
                    })}
                  />
                  <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel htmlFor="phone">Phone (optional)</FormLabel>
                  <Input id="phone" placeholder="+44 7777 12345" {...register("phone")} />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel htmlFor="message">Message</FormLabel>
                  <Textarea id="message" placeholder="Your message ..." {...register("message")} />
                </FormControl>
              </Box>
              <Flex mt={6} flexDirection={"column"}>
                <Button
                  mt={[4, 0]}
                  disabled={timesUp}
                  fontWeight="normal"
                  size="md"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Inquire Now
                </Button>
                <Box mt="4" textAlign={"center"}>
                  You can reserve your boat for 72 hours for free. You won't be charged.
                </Box>
              </Flex>
              {/* <ReserveNavigation
          back="Not ready yet? Send Inquiry"
          next="Next: Final Details >"
          isSubmitting={isSubmitting}
          timesUp={false}
          mt={6}
        /> */}
            </form>
          )}
        </>
      )}
    </ReserveTemplate>
  )
}

export default ReserveDetails
