import dayjs from "dayjs"

export const dateToLongString = (date: string) => dayjs(date).format("ddd, MMM D, YYYY")

export const durationInDays = (checkin: string, checkout: string, unit: string) => {
  const duration = dayjs(checkout).diff(checkin, "days")
  if (duration <= 1) {
    return `${duration} ${unit}`
  } else {
    return `${duration} ${unit}s`
  }
}
