import React, { useEffect } from "react"
import PropTypes from "prop-types"
import smoothscroll from "smoothscroll-polyfill"
import { Heading } from "@chakra-ui/react"
import Link from "./Link"

const Header = ({ type }) => {
  if (type === "reserve") {
    return (
      <Heading as="h3" variant="h3" id="thankyou_header" mt={[8, 0]} mb={8}>
        Thank you for your reservation
      </Heading>
    )
  }
  return (
    <Heading as="h3" variant="h3" id="thankyou_header" mt={[8, 0]} mb={8}>
      Thank you for your inquiry
    </Heading>
  )
}

Header.propTypes = {
  type: PropTypes.string.isRequired,
}

const ReservationBody = () => (
  <>
    <p>
      Congratulations! You are one step closer to getting onboard and sailing
      away.
    </p>
    <p>Your boat is now reserved for the next 72 hours.</p>
    <Heading as="h4" variant="h4" size="lg" color="primary" mt={8} mb={4}>
      What happens next
    </Heading>
    <p>You will receive an e-mail confirming this reservation.</p>
    <p>
      One of our sailing experts will be in touch shortly with more details on
      payment terms and next steps.
    </p>
    <p>
      Remember, we are there for you on WhatsApp, e-mail or by phone - contact
      us if you have any questions
    </p>
  </>
)

const InquiryBody = () => (
  <>
    <p>One of our sailing experts will be in touch with you shortly.</p>
    <p>
      In the meantime, get inspired reading through our
{" "}
      <Link to="/get-inspired" sx={{ variant: "links.primary" }}>
        sailing itineraries and destination guides
      </Link>
{" "}
      or get smart on how to make the most from your trip with our
{" "}
      <Link to="/sailing-tips" sx={{ variant: "links.primary" }}>
        sailing tips.
      </Link>
    </p>
  </>
)

const ThankYou = ({ type }) => {
  useEffect(() => {
    smoothscroll.polyfill()
    // eslint-disable-next-line no-undef
    document
      .querySelector("h3#thankyou_header")
      .scrollIntoView({ behavior: "smooth" })
  })

  return (
    <div>
      <Header type={type} />
      {type === "reserve" ? <ReservationBody /> : <InquiryBody />}
    </div>
  )
}

ThankYou.propTypes = {
  type: PropTypes.string.isRequired,
}

export default ThankYou
